import React from 'react'
import styled from 'styled-components'

const MainContainer = styled.section`
  color: rgba(255, 255, 255, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: -1;

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const FloconArticle = styled.article`
  pointer-events: none;
  left: auto;
  will-change: transform;
  display: inline-block;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  animation: falling, opacity_flakes;
  animation-iteration-count: infinite;

  opacity: 0;

  &:nth-child(3n + 0) { animation-duration: 14s; }
  &:nth-child(3n + 1) { animation-duration: 18s; }
  &:nth-child(3n + 2) { animation-duration: 24s; }

  /* Set individual origins */
  &:nth-child(4n + 0) { transform-origin: -5% -5%; }
  &:nth-child(4n + 1) { transform-origin: 0% 0; }
  &:nth-child(4n + 2) { transform-origin: 0 -30%; }
  &:nth-child(4n + 3) { transform-origin: -100% -100%; }

  /* Delay the starting times */
  &:nth-of-type(5n + 0) { animation-delay: 0s; }
  &:nth-of-type(5n + 1) { animation-delay: 1s; }
  &:nth-of-type(5n + 2) { animation-delay: 2s; }
  &:nth-of-type(5n + 3) { animation-delay: 3s; }
  &:nth-of-type(5n + 4) { animation-delay: 4s; }

  /* Tweak timing functions - a tad more random */
  &:nth-of-type(8n + 0) { animation-timing-function: ease-in-out; }
  &:nth-of-type(8n + 1) { animation-timing-function: ease-out; }
  &:nth-of-type(8n + 2) { animation-timing-function: cubic-bezier(0.2, 0.5, 0.4, 0.9); }
  &:nth-of-type(8n + 3) { animation-timing-function: ease-in; }
  &:nth-of-type(8n + 4) { animation-timing-function: ease; }
  &:nth-of-type(8n + 5) { animation-timing-function: cubic-bezier(0.2, 0.3, 0.8, 0.9); }
  &:nth-of-type(8n + 6) { animation-timing-function: ease-in; }
  &:nth-of-type(8n + 7) { animation-timing-function: ease-out; }
`;

const FloconImage = styled.img`
  width: 44px;
  height: 44px;

  position: absolute;
  top: -10%;

`;
const FloconImageBig = styled.img`
  width: 128px;
  height: 128px;

  position: absolute;
  top: -8%;
`;
const FloconImageMedium = styled.img`
  width: 72px;
  height: 72px;

  position: absolute;
  top: -5%;
`;
const FloconImageSmall = styled.img`
  width: 32px;
  height: 32px;

  position: absolute;
  top: -6%;
`;

const Flocon = () => {
  return (
    <MainContainer aria-hidden="true">
      <FloconArticle className="snowflake">
        <FloconImageBig src={process.env.PUBLIC_URL + './images/Flow.png'} />
      </FloconArticle>
      <FloconArticle className="snowflake">
        <FloconImageSmall src={process.env.PUBLIC_URL + './images/Flow1.png'} />
      </FloconArticle>
      <FloconArticle className="snowflake">
        <FloconImageMedium src={process.env.PUBLIC_URL + './images/flocon.png'} />
      </FloconArticle>
      <FloconArticle className="snowflake">
        <FloconImage src={process.env.PUBLIC_URL + './images/Flow3.png'} />
      </FloconArticle>

      <FloconArticle className="snowflake">
        <FloconImageBig src={process.env.PUBLIC_URL + './images/flocon.png'} />
      </FloconArticle>
      <FloconArticle className="snowflake">
        <FloconImageMedium src={process.env.PUBLIC_URL + './images/Flow.png'} />
      </FloconArticle>
      <FloconArticle className="snowflake">
        <FloconImageSmall src={process.env.PUBLIC_URL + './images/flocon.png'} />
      </FloconArticle>
      <FloconArticle className="snowflake">
        <FloconImageSmall src={process.env.PUBLIC_URL + './images/Flow3.png'} />
      </FloconArticle>

      <FloconArticle className="snowflake">
        <FloconImageMedium src={process.env.PUBLIC_URL + './images/Flow1.png'} />
      </FloconArticle>
      <FloconArticle className="snowflake">
        <FloconImageBig src={process.env.PUBLIC_URL + './images/Flow3.png'} />
      </FloconArticle>
      <FloconArticle className="snowflake">
        <FloconImageSmall src={process.env.PUBLIC_URL + './images/flocon.png'} />
      </FloconArticle>
      <FloconArticle className="snowflake">
        <FloconImageSmall src={process.env.PUBLIC_URL + './images/Flow.png'} />
      </FloconArticle>

      <FloconArticle className="snowflake">
        <FloconImageSmall src={process.env.PUBLIC_URL + './images/Flow.png'} />
      </FloconArticle>
      <FloconArticle className="snowflake">
        <FloconImage src={process.env.PUBLIC_URL + './images/Flow2.png'} />
      </FloconArticle>
      <FloconArticle className="snowflake">
        <FloconImageBig src={process.env.PUBLIC_URL + './images/Flow1.png'} />
      </FloconArticle>
      <FloconArticle className="snowflake">
        <FloconImageMedium src={process.env.PUBLIC_URL + './images/Flow3.png'} />
      </FloconArticle>
      
      <FloconArticle className="snowflake">
        <FloconImageBig src={process.env.PUBLIC_URL + './images/Flow.png'} />
      </FloconArticle>
      <FloconArticle className="snowflake">
        <FloconImageSmall src={process.env.PUBLIC_URL + './images/Flow1.png'} />
      </FloconArticle>
      <FloconArticle className="snowflake">
        <FloconImageMedium src={process.env.PUBLIC_URL + './images/flocon.png'} />
      </FloconArticle>
      <FloconArticle className="snowflake">
        <FloconImage src={process.env.PUBLIC_URL + './images/Flow3.png'} />
      </FloconArticle>

      <FloconArticle className="snowflake">
        <FloconImageBig src={process.env.PUBLIC_URL + './images/flocon.png'} />
      </FloconArticle>
      <FloconArticle className="snowflake">
        <FloconImageMedium src={process.env.PUBLIC_URL + './images/Flow.png'} />
      </FloconArticle>
      <FloconArticle className="snowflake">
        <FloconImageSmall src={process.env.PUBLIC_URL + './images/Flow1.png'} />
      </FloconArticle>
      <FloconArticle className="snowflake">
        <FloconImageSmall src={process.env.PUBLIC_URL + './images/Flow3.png'} />
      </FloconArticle>

      <FloconArticle className="snowflake">
        <FloconImageMedium src={process.env.PUBLIC_URL + './images/Flow3.png'} />
      </FloconArticle>
      <FloconArticle className="snowflake">
        <FloconImageBig src={process.env.PUBLIC_URL + './images/flocon.png'} />
      </FloconArticle>
      <FloconArticle className="snowflake">
        <FloconImageSmall src={process.env.PUBLIC_URL + './images/Flow1.png'} />
      </FloconArticle>
      <FloconArticle className="snowflake">
        <FloconImageSmall src={process.env.PUBLIC_URL + './images/Flow.png'} />
      </FloconArticle>

      <FloconArticle className="snowflake">
        <FloconImageSmall src={process.env.PUBLIC_URL + './images/Flow3.png'} />
      </FloconArticle>
      <FloconArticle className="snowflake">
        <FloconImage src={process.env.PUBLIC_URL + './images/Flow.png'} />
      </FloconArticle>
      <FloconArticle className="snowflake">
        <FloconImageBig src={process.env.PUBLIC_URL + './images/Flow1.png'} />
      </FloconArticle>
      <FloconArticle className="snowflake">
        <FloconImageMedium src={process.env.PUBLIC_URL + './images/flocon.png'} />
      </FloconArticle>

    </MainContainer>
  )
}

export default Flocon
