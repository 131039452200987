import React from "react";
import styled from "styled-components";


const SocialMediaContainer = styled.article`
  width: 90%;

  position: absolute;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);

  @media screen and (min-height: 800px){
    bottom: 120px;
  }
`;

const Explications = styled.p`
  margin: 0 0 10px 0;
  font-size: 14px;
  text-align: center;

  @media screen and (min-width: 500px) {
    font-size: 18px;
  }
`;

const ShareButtons = () => {

  return (
    <SocialMediaContainer>
      <Explications> Voici votre carte de voeux : vous pouvez la télécharger. N'hésitez pas à la partager sur vos réseaux sociaux !</Explications>
    </SocialMediaContainer>
  );
};

export default ShareButtons;