import './App.css';

import Flocon from './components/Flocons/Flocon';
import Header from './components/Header';
import DataForm from './components/DataForm';
import Footer from './components/Footer';

function App() {  
  return (
    <div className="App">
      <Flocon />
      <Header />
      <DataForm />
      <Footer />
    </div>
  );
}

export default App;