import React, { useEffect, useState, useCallback, useRef } from 'react'

import { toPng } from 'html-to-image';
import styled from 'styled-components'
import { AiOutlineClose } from "react-icons/ai";

import ShareButtons from './Share/ShareButtons';


const CardContainer = styled.section`
  width: 90%;
  max-width: 700px;
  height: 91.8vh;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 5px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media screen and (min-height: 1366px){
    height: 80vh;
  }
`;

const CardClose = styled.article`
  width: 95%;
  height: 55px;
  margin: 0 auto;
  padding: 10px:
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    svg {
      border: 1px solid #7600ff;
      border-radius: 50%;
      font-size: 30px;
      padding: 5px;
      transition: all .9s ease;
      cursor: pointer;
      fill: #7600ff;

      &:hover {
        transform: rotate(-360deg);
        background-color: #7600ff;
        fill: white;
      }
    }
  }
`;

const DownloadBtn = styled.a`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;

  text-decoration: none;
  text-align: center;
  background-color: #7600ff;
  color: white;
  font-family: Perroquet-Bold, sans-serif;
  font-size: 20px;
  padding: 15px;

  outline: transparent;
  border: transparent;
  border-radius: 0 0 5px 5px;
  cursor: pointer;
  transition: letter-spacing .4s ease;

  &:hover { letter-spacing: 0.2rem; }
`;

const CanvasContainer = styled.article`
  width: 90%;
  height: 40vh;
  margin: 0 auto;
  background-color: rgb(37, 0, 55);
  padding: 10px 0;
  z-index: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  position: relative;

  .snowflakeCss {
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translateX(-50%);
  }

  @media screen and (max-width: 400px){
    .snowflakeCss {
    top: 3%;
  }
  }
  @media screen and (min-width: 500px){
    width: 400px;
    height: 400px;
  }

  @media screen and (min-height: 1024px){
    width: 580px;
    height: 580px;
  }

  @media screen and (min-height: 1024px){
    .snowflakeCss {
    top: 10%;
  }
`;

const Bonneannee = styled.p`
  color: white;
  text-align: center;
  font-size: 30px;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  z-index: 9999 !important;

  @media screen and (max-width: 320px){
    font-size: 20px;
  }
`;
const Annee = styled.p`
  -webkit-text-stroke: 2px white;
  color: transparent;
  font-size: 32vw;
  font-family: Perroquet-Bold, sans-serif;
  text-align: center;
  height: fit-content;
  z-index: 9999 !important;

  @media screen and (max-width: 320px){
    font-size: 70px;
  }
  @media screen and (min-width: 500px){
    font-size: 170px;
  }
`;
const Entreprise = styled.p`
  color: white;
  text-transform: uppercase;
  text-align: center;
  font-size: 24px;
  letter-spacing: 0.2rem;
  z-index: 9999 !important;

  @media screen and (max-width: 320px){
    font-size: 6vw;
  }
`;

const SnowFlakesCss = styled.figure`
  font-size: 100px;
  color: rgba(160, 36, 36, 0.815);
  width: 5em;
  height: 5em;
  display: flex;
  justify-content: center;
  z-index: 1 !important;

  @media screen and (max-width: 320px){
    font-size: 50px;
  }
  @media screen and (max-width: 400px){
    font-size: 90px;
  }
  @media screen and (max-width: 375px){
    font-size: 70px;
  }
  @media screen and (min-height: 1024px){
    font-size: 120px;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 0.15em;
    height: 1.75em;
    background-color: currentColor;
    border-radius: 0.5em;
    position: absolute;
    transform-origin: bottom;
    transform: rotate(calc((var(--n) - 1) * 60deg));
    z-index: 1;

    &:nth-child(1) {--n: 1;}
    &:nth-child(2) {--n: 2;}
    &:nth-child(3) {--n: 3;}
    &:nth-child(4) {--n: 4;}
    &:nth-child(5) {--n: 5;}
    &:nth-child(6) {--n: 6;}

    span:nth-child(1) {
      width: 0.15em;
      height: 0.17em;
      background-color: currentColor;
      border-radius: 50%;
      opacity: .5;
    }
    span:nth-child(2),
    span:nth-child(3),
    span:nth-child(4) {
      width: 0.4em;
      height: 0.4em;
      border: 0.1em solid;
      border-width: 0.15em;
      border-style: none solid solid none;
      border-radius: 0.1em;
      transform: rotate(45deg);
    }
    span:nth-child(2) { color: blue; }
    span:nth-child(3) { color: green; }
    span:nth-child(4) { color: yellow; }

    span:nth-child(2) { margin-top: -0.2em; }
    span:nth-child(3) { margin-top: -0.15em; }
    span:nth-child(4) { margin-top: -0.1em; }

    @media screen and (min-width: 500px) {
      height: 2em;

      span:nth-child(2) { margin-top: -0.1em; }
      span:nth-child(3) { margin-top: -0.1em; }
      span:nth-child(4) { margin-top: 0em; }
    }
  }
`

  
const Modal = ({ nom, date, employe, setShow }) => {

  // lettres 1, 2, 3, 4 du nom de l'entreprise
  const [firstLetter, setFirstLetter] = useState('');
  const [secondLetter, setSecondLetter] = useState('');
  const [thirdLetter, setThirdLetter] = useState('');
  const [fourthLetter, setFourthLetter] = useState('');

  // width & height des branches selon params
  const [size, setSize] = useState('');

  // opacity des branches selon params
  const [firstBranch, setFirstBranch] = useState('');
  const [secondBranch, setSecondBranch] = useState('');
  const [thirdBranch, setThirdBranch] = useState('');

  // valeurs de type number : année et nb d'employés
  let dateCreation = parseInt(date);
  let nombreEmployes = parseInt(employe);

  // nom de l'entreprise passé en majuscules
  let nomEntreprise = nom;
  let nomCapital = nomEntreprise.toUpperCase();

  let letter = nomCapital.charAt(0);
  let letterSecond = nomCapital.charAt(1);
  let letterThird = nomCapital.charAt(2);
  let letterFourth = nomCapital.charAt(3);

  const currentDate = new Date();
  const yearofCurrentDate = currentDate.getFullYear();

  useEffect(() => {

    if(dateCreation >= (yearofCurrentDate - 5)){
      setSize('0.4em');
    } else if (dateCreation < (yearofCurrentDate - 5) && dateCreation > (yearofCurrentDate - 10)) {
      setSize('0.5em');
    } else if (dateCreation < (yearofCurrentDate - 10)) {
      setSize('0.6em');
    }

    if(nombreEmployes <= 10){
      setFirstBranch('0');
      setSecondBranch('1');
      setThirdBranch('0');
    } else if ((nombreEmployes > 10) && (nombreEmployes <= 100)) {
      setFirstBranch('0');
      setSecondBranch('1');
      setThirdBranch('1');
    } else if (nombreEmployes > 100) {
      setFirstBranch('1');
      setSecondBranch('1');
      setThirdBranch('1');
    }

    if(letter === 'A') {
      setFirstLetter('#ff000098');
    } else if(letter === 'B') {
      setFirstLetter('#F8127B98');
    } else if(letter === 'C') {
      setFirstLetter('#EF5A2498');
    } else if(letter === 'D') {
      setFirstLetter('#F5911E98');
    } else if(letter === 'E') {
      setFirstLetter('#FFFFFF98');
    } else if(letter === 'F') {
      setFirstLetter('#FAEC2198');
    } else if(letter === 'G') {
      setFirstLetter('#D7DE2198');
    } else if(letter === 'H') {
      setFirstLetter('#8AC43F98');
    } else if(letter === 'I') {
      setFirstLetter('#39B34A98');
    } else if(letter === 'J') {
      setFirstLetter('#00683798');
    } else if(letter === 'K') {
      setFirstLetter('#22B37398');
    } else if(letter === 'L') {
      setFirstLetter('#00A79B98');
    } else if(letter === 'M') {
      setFirstLetter('#29A9E098');
    } else if(letter === 'N') {
      setFirstLetter('#0071BA98');
    } else if(letter === 'O') {
      setFirstLetter('#0000FF98');
    } else if(letter === 'P') {
      setFirstLetter('#662D8F98');
    } else if(letter === 'Q') {
      setFirstLetter('#91278D98');
    } else if(letter === 'R') {
      setFirstLetter('#9C005D98');
    } else if(letter === 'S') {
      setFirstLetter('#D2145A98');
    } else if(letter === 'T') {
      setFirstLetter('#EB1E7998');
    } else if(letter === 'U') {
      setFirstLetter('#C5B09798');
    } else if(letter === 'V') {
      setFirstLetter('#97847598');
    } else if(letter === 'W') {
      setFirstLetter('#8A623998');
    } else if(letter === 'X') {
      setFirstLetter('#A47C5298');
    } else if(letter === 'Y') {
      setFirstLetter('#C49A6D98');
    } else if(letter === 'Z') {
      setFirstLetter('#73635798');
    }

    if(letterSecond === 'A') {
      setSecondLetter('#FF000098');
    } else if(letterSecond === 'B') {
      setSecondLetter('#F8127B98');
    } else if(letterSecond === 'C') {
      setSecondLetter('#EF5A2498');
    } else if(letterSecond === 'D') {
      setSecondLetter('#F5911E98');
    } else if(letterSecond === 'E') {
      setSecondLetter('#FFFFFF98');
    } else if(letterSecond === 'F') {
      setSecondLetter('#FAEC2198');
    } else if(letterSecond === 'G') {
      setSecondLetter('#D7DE2198');
    } else if(letterSecond === 'H') {
      setSecondLetter('#8AC43F98');
    } else if(letterSecond === 'I') {
      setSecondLetter('#39B34A98');
    } else if(letterSecond === 'J') {
      setSecondLetter('#00683798');
    } else if(letterSecond === 'K') {
      setSecondLetter('#22B37398');
    } else if(letterSecond === 'L') {
      setSecondLetter('#00A79B98');
    } else if(letterSecond === 'M') {
      setSecondLetter('#29A9E098');
    } else if(letterSecond === 'N') {
      setSecondLetter('#0071BA98');
    } else if(letterSecond === 'O') {
      setSecondLetter('#0000FF98');
    } else if(letterSecond === 'P') {
      setSecondLetter('#662D8F98');
    } else if(letterSecond === 'Q') {
      setSecondLetter('#91278D98');
    } else if(letterSecond === 'R') {
      setSecondLetter('#9C005D98');
    } else if(letterSecond === 'S') {
      setSecondLetter('#D2145A98');
    } else if(letterSecond === 'T') {
      setSecondLetter('#EB1E7998');
    } else if(letterSecond === 'U') {
      setSecondLetter('#C5B09798');
    } else if(letterSecond === 'V') {
      setSecondLetter('#97847598');
    } else if(letterSecond === 'W') {
      setSecondLetter('#8A623998');
    } else if(letterSecond === 'X') {
      setSecondLetter('#A47C5298');
    } else if(letterSecond === 'Y') {
      setSecondLetter('#C49A6D98');
    } else if(letterSecond === 'Z') {
      setSecondLetter('#73635798');
    }

    if(letterThird === 'A') {
      setThirdLetter('#FF000098');
    } else if(letterThird === 'B') {
      setThirdLetter('#F8127B98');
    } else if(letterThird === 'C') {
      setThirdLetter('#EF5A2498');
    } else if(letterThird === 'D') {
      setThirdLetter('#F5911E98');
    } else if(letterThird === 'E') {
      setThirdLetter('#FFFFFF98');
    } else if(letterThird === 'F') {
      setThirdLetter('#FAEC2198');
    } else if(letterThird === 'G') {
      setThirdLetter('#D7DE2198');
    } else if(letterThird === 'H') {
      setThirdLetter('#8AC43F98');
    } else if(letterThird === 'I') {
      setThirdLetter('#39B34A98');
    } else if(letterThird === 'J') {
      setThirdLetter('#00683798');
    } else if(letterThird === 'K') {
      setThirdLetter('#22B37398');
    } else if(letterThird === 'L') {
      setThirdLetter('#00A79B98');
    } else if(letterThird === 'M') {
      setThirdLetter('#29A9E098');
    } else if(letterThird === 'N') {
      setThirdLetter('#0071BA98');
    } else if(letterThird === 'O') {
      setThirdLetter('#0000FF98');
    } else if(letterThird === 'P') {
      setThirdLetter('#662D8F98');
    } else if(letterThird === 'Q') {
      setThirdLetter('#91278D98');
    } else if(letterThird === 'R') {
      setThirdLetter('#9C005D98');
    } else if(letterThird === 'S') {
      setThirdLetter('#D2145A98');
    } else if(letterThird === 'T') {
      setThirdLetter('#EB1E7998');
    } else if(letterThird === 'U') {
      setThirdLetter('#C5B09798');
    } else if(letterThird === 'V') {
      setThirdLetter('#97847598');
    } else if(letterThird === 'W') {
      setThirdLetter('#8A623998');
    } else if(letterThird === 'X') {
      setThirdLetter('#A47C5298');
    } else if(letterThird === 'Y') {
      setThirdLetter('#C49A6D98');
    } else if(letterThird === 'Z') {
      setThirdLetter('#73635798');
    }

    if(letterFourth === 'A') {
      setFourthLetter('#FF000098');
    } else if(letterFourth === 'B') {
      setFourthLetter('#F8127B98');
    } else if(letterFourth === 'C') {
      setFourthLetter('#EF5A2498');
    } else if(letterFourth === 'D') {
      setFourthLetter('#F5911E98');
    } else if(letterFourth === 'E') {
      setFourthLetter('#FFFFFF98');
    } else if(letterFourth === 'F') {
      setFourthLetter('#FAEC2198');
    } else if(letterFourth === 'G') {
      setFourthLetter('#D7DE2198');
    } else if(letterFourth === 'H') {
      setFourthLetter('#8AC43F98');
    } else if(letterFourth === 'I') {
      setFourthLetter('#39B34A98');
    } else if(letterFourth === 'J') {
      setFourthLetter('#00683798');
    } else if(letterFourth === 'K') {
      setFourthLetter('#22B37398');
    } else if(letterFourth === 'L') {
      setFourthLetter('#00A79B98');
    } else if(letterFourth === 'M') {
      setFourthLetter('#29A9E098');
    } else if(letterFourth === 'N') {
      setFourthLetter('#0071BA98');
    } else if(letterFourth === 'O') {
      setFourthLetter('#0000FF98');
    } else if(letterFourth === 'P') {
      setFourthLetter('#662D8F98');
    } else if(letterFourth === 'Q') {
      setFourthLetter('#91278D98');
    } else if(letterFourth === 'R') {
      setFourthLetter('#9C005D98');
    } else if(letterFourth === 'S') {
      setFourthLetter('#D2145A98');
    } else if(letterFourth === 'T') {
      setFourthLetter('#EB1E7998');
    } else if(letterFourth === 'U') {
      setFourthLetter('#C5B09798');
    } else if(letterFourth === 'V') {
      setFourthLetter('#97847598');
    } else if(letterFourth === 'W') {
      setFourthLetter('#8A623998');
    } else if(letterFourth === 'X') {
      setFourthLetter('#A47C5298');
    } else if(letterFourth === 'Y') {
      setFourthLetter('#C49A6D98');
    } else if(letterFourth === 'Z') {
      setFourthLetter('#73635798');
    }
  }, [dateCreation, yearofCurrentDate, nombreEmployes, letter, letterSecond, letterThird, letterFourth])

  const ref = useRef(null)

  const onButtonClick = useCallback(() => {
    if (ref.current === null) return;

    toPng(ref.current, {})
      .then((dataUrl) => {
        const link = document.createElement('a')
        link.download = 'cartedevoeux.png'
        link.href = dataUrl
        link.click()
      })
      .catch((err) => {
        console.log(err)
      })
  }, [ref])

  return (
    <CardContainer id="cardContainer">
      <CardClose>
        <div>
          <AiOutlineClose onClick={() => setShow(false)} />
        </div>
      </CardClose>

      <div ref={ref}>
      <CanvasContainer id="canvas">
        <Bonneannee>Bonne année</Bonneannee>
        <Annee>2022</Annee>
        <Entreprise>{nom}</Entreprise>

        <SnowFlakesCss className="snowflakeCss">
          <div style={{color: firstLetter}}>
            <span style={{color: firstLetter}}></span>
            <span style={{color: secondLetter, width: size, height: size, opacity: firstBranch}}></span>           
            <span style={{color: thirdLetter, width: size, height: size, opacity: secondBranch}}></span>         
            <span style={{color: fourthLetter, width: size, height: size, opacity: thirdBranch}}></span>
          </div>
          <div style={{color: firstLetter}}>
            <span style={{color: firstLetter}}></span>
            <span style={{color: secondLetter, width: size, height: size, opacity: firstBranch}}></span>           
            <span style={{color: thirdLetter, width: size, height: size, opacity: secondBranch}}></span>         
            <span style={{color: fourthLetter, width: size, height: size, opacity: thirdBranch}}></span>
          </div>
          <div style={{color: firstLetter}}>
            <span style={{color: firstLetter}}></span>
            <span style={{color: secondLetter, width: size, height: size, opacity: firstBranch}}></span>           
            <span style={{color: thirdLetter, width: size, height: size, opacity: secondBranch}}></span>         
            <span style={{color: fourthLetter, width: size, height: size, opacity: thirdBranch}}></span>
          </div>
          <div style={{color: firstLetter}}>
            <span style={{color: firstLetter}}></span>
            <span style={{color: secondLetter, width: size, height: size, opacity: firstBranch}}></span>           
            <span style={{color: thirdLetter, width: size, height: size, opacity: secondBranch}}></span>         
            <span style={{color: fourthLetter, width: size, height: size, opacity: thirdBranch}}></span>
          </div>
          <div style={{color: firstLetter}}>
            <span style={{color: firstLetter}}></span>
            <span style={{color: secondLetter, width: size, height: size, opacity: firstBranch}}></span>           
            <span style={{color: thirdLetter, width: size, height: size, opacity: secondBranch}}></span>         
            <span style={{color: fourthLetter, width: size, height: size, opacity: thirdBranch}}></span>
          </div>
          <div style={{color: firstLetter}}>
            <span style={{color: firstLetter}}></span>
            <span style={{color: secondLetter, width: size, height: size, opacity: firstBranch}}></span>           
            <span style={{color: thirdLetter, width: size, height: size, opacity: secondBranch}}></span>         
            <span style={{color: fourthLetter, width: size, height: size, opacity: thirdBranch}}></span>
          </div>
        </SnowFlakesCss>
      </CanvasContainer>
      </div>

      <ShareButtons />
      <DownloadBtn onClick={onButtonClick}>Télécharger</DownloadBtn>
    </CardContainer>
  )
}

export default Modal
