import React, { useState } from 'react';
import styled from 'styled-components';
import Mentions from './MentionsLegales/Mentions';

const FooterContainer = styled.footer`
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0 5%;
  margin-top: 60px;
`;

const FooterSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
`
const Anchor = styled.a`
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 500;
  background-color: #3C1358;
  padding: 4px;

  transition: all .6s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: #7600ff;
    color: #FFFFFF;
  }
`;


const Footer = () => {
  const [isMentions, setIsMentions] = useState(false);

  const handleMention = () => setIsMentions(!isMentions);

  return (
    <FooterContainer>
      <FooterSection>
        <Anchor 
        onClick={handleMention}
        style={{ backgroundColor: isMentions ? "#7500ff" : "", color: isMentions ? "#FFFFFF" : "" }}
        >Mentions Légales</Anchor>
      </FooterSection>

      {isMentions && <Mentions/>}
    </FooterContainer> 
  )
}

export default Footer
