import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Commentcamarche from "./Infos/Commentcamarche";
import Modal from "./Modal";

const MainContainer = styled.main`
  width: 100%;

  @media screen and (min-height: 1024px){
    margin-top: 100px;
  }
`;
const FormSection = styled.section`
  width: 80%;
  max-width: 700px;
  height: 450px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  @media screen and (min-height: 1366px){
    margin: 200px auto;
  }
`;

const Input = styled.input`
  border: 1px solid #7600ff;
  outline: none;

  padding: 1rem 0;
  border-radius: 15px;
  background-color: rgba(37, 0, 55, 0.6);
  color: rgba(255,255,255, 0.5);
  font-size: 20px;
  text-align: center;
  font-family: Perroquet, sans-serif;

  transition: border .35s ease-in-out;

  &&::placeholder {
    color: rgba(255,255,255, 0.5);
    font-family: Perroquet, sans-serif;
  }
  &&:focus {
    color: rgba(255,255,255, 0.5);
    border: 2px dashed rgb(122, 4, 255);
    background-color: rgba(37, 0, 55, 0.6);
  }
  &&:valid { 
    color: rgba(255,255,255, 0.5);
    background-color: rgba(37, 0, 55, 0.6);
  }

  &&:autofill {
    color: rgba(255,255,255, 0.5) !important;
    background-color: rgba(37, 0, 55, 0.6) !important;
  }
  &&:-webkit-autofill{
    -webkit-text-fill-color: rgba(255,255,255, 0.5) !important;
    -webkit-box-shadow: 0 0 0 40px rgb(37, 0, 55) inset !important;
    border: 1px solid #7600ff;
}
`;

const ErrorMsg = styled.p`
  color: white;
  text-align: center;
`;

const BtnVoeux = styled.button`
  width: 100%;
  margin: 0 auto;
  font-family: Perroquet-Bold, sans-serif;
  font-size: 20px;
  padding: 15px;
  background-color: rgb(122, 4, 255);
  border-radius: 15px;
  border: transparent;
  outline: transparent;
  cursor: pointer;
  color: white;
  transition: letter-spacing .4s ease;

  &:hover { letter-spacing: 0.2rem; }
`;

function DataForm () {
  const [show, setShow] = useState(false);

  const [nomEnt, setNomEnt] = useState(null);
  const [dateEnt, setDateEnt] = useState(null);
  const [employeEnt, setEmployeEnt] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search); 
    const nomEntreprise = urlParams.get('nom');
    const dateCreation = urlParams.get('date');
    const nombreEmploye = urlParams.get('employes');

    setNomEnt(nomEntreprise);
    setDateEnt(dateCreation);
    setEmployeEnt(nombreEmploye);

    if(nomEntreprise !== null && dateCreation !== null && nombreEmploye !== null){
      setShow(true);
    } else {
      setShow(false);
      console.log('aucune valeur saisie, vous pouvez rentrer directement dans l\'url vos valeurs comme ceci : /?nom=XXX&date=YYYY&employes=ZZZZ ');
    }
  }, [])

  function validation(){
    if(nomEnt !== null && dateEnt !== null && employeEnt !== null){
      setShow(true);
      document.getElementById('error').textContent = "";
    } else {
      document.getElementById('error').textContent = "Veuillez remplir tous les champs pour valider";
    }
  }

  return (
    <MainContainer>
      <Commentcamarche />
      
      <FormSection>
        <Input
          value={nomEnt || ''}
          onChange={e => setNomEnt(e.target.value)}
          name="name"
          label="Name"
          placeholder="Nom"
        />
        <Input
          value={dateEnt || ''}
          onChange={e => setDateEnt(e.target.value)}
          name="created"
          label="Created"
          placeholder="Date de création"
        />
        <Input
          value={employeEnt || ''}
          onChange={e => setEmployeEnt(e.target.value)}
          name="employes"
          label="Employes"
          placeholder="Nombre d'employés"
        />

        <ErrorMsg id="error"></ErrorMsg>

        <BtnVoeux onClick={() => validation()}>Valider</BtnVoeux>
      </FormSection>

      {show ? (
        <Modal nom={nomEnt} date={dateEnt} employe={employeEnt} setShow={setShow} />       
      ) : null}

    </MainContainer>
  );
};

export default DataForm;
