import React, { useState } from "react";
import styled from "styled-components";
import { AiOutlineClose } from "react-icons/ai";
import { GoPlus } from "react-icons/go";

const AccordionSection = styled.section`
  width: 80%;
  max-width: 700px;
  margin: 0 auto;
`;

const Allwrapped = styled.article`
  margin: 20px 0 60px 0;
  padding: 5px 5px 10px 5px;
  border-radius: 5px;

  background-color: rgba(37, 0, 55, 0.6);
  border-bottom: 1px solid #7600ff;
  cursor: pointer;
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  span #openInfos path { fill: #ffffff50; }
  span #closeInfos path { fill: #7600ff; }
`;

const Question = styled.h3`
  font-size: 3.2vw;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 500;
  text-transform: uppercase;
  transition: color 0.6s ease-in-out;
  letter-spacing: 0.1rem;

  @media (min-width: 768px) {
    font-size: 26px;
  }
`;

const Informations = styled.p`
  font-size: 14px;
  margin: 30px 0px 0px 0px;
  color: whitesmoke;
  line-height: 1.4rem;
  font-weight: 400;
`;

const Commentcamarche = () => {
  const [showInfos, setShowInfos] = useState(true);

  const toggleinfos = () => {
    if (showInfos) setShowInfos(false);
    else setShowInfos(true);
  };

  return (
    <AccordionSection>
      <Allwrapped>
        <Wrap>
          <Question
            onClick={() => toggleinfos()}
            style={{ color: showInfos ? "rgb(118, 0, 255)" : "" }}
          >Comment ça Marche ?
          </Question>
          <span>{showInfos ? 
            <AiOutlineClose id="closeInfos" onClick={() => toggleinfos()} /> : 
            <GoPlus id="openInfos" onClick={() => toggleinfos()} />
          }</span>
        </Wrap>

        {showInfos && (
          <Informations>Créez votre carte de voeux à télécharger et à partager ! 
          <br />{" "} 
          <br />{" "}
          Toutes les informations demandées ne sont pas conservées et ne servent qu'a la génération d'un flocon personnalisé pour le visuel de cette carte de voeux interactive.
          </Informations>
        )}
      </Allwrapped>
    </AccordionSection>
  );
};

export default Commentcamarche;
