import React from 'react';
import styled from 'styled-components';

const MentionContainer = styled.article`
  margin: 5rem 0rem;
  display: flex;
  flex-direction: column;
`

const MentionTextContainer = styled.div`
  width: auto;
  margin-bottom: 25px;
`

const MentionText = styled.p`
  font-family: "Perroquet-Regular",Arial, Helvetica, sans-serif;
  color: rgba(255,255,255, 0.5);
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 25px;
  letter-spacing: 1px;
  line-height: 1.4rem;
`

const MentionTextDeux = styled.p`
  font-family: "Perroquet-Regular",Arial, Helvetica, sans-serif;
  color: rgba(255,255,255, 0.5);
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 1.4rem;
`

const Mentions = () => {
  return (
    <MentionContainer id="mentionsLeg">
        <MentionText>Mentions Légales du site CartedeVoeux.com</MentionText>
        <MentionText>LE SITE EST LA PROPRIÉTÉ DE CLÉMENT CATHERINE, AINSI QUE TOUT SON CONTENU. LA STRUCTURE GÉNÉRALE AINSI QUE LES TEXTES, IMAGES ET VIDÉOS, SON SAVOIR-FAIRE ET TOUS LES AUTRES ÉLÉMENTS COMPOSANT LE SITE SONT LA PROPRIÉTÉ EXCLUSIVE DE L’ÉDITEUR DU SITE. CES RÈGLES S’APPLIQUENT À TOUT LE SITE WEB SAUF MENTION CONTRAIRE.</MentionText>

        <MentionTextContainer>
          <MentionTextDeux>Dénomination sociale ou raison sociale : Perroquet</MentionTextDeux>
          <MentionTextDeux>adresse du siège social : 37 rue Guibal, 13001 MARSEILLE</MentionTextDeux>
          <MentionTextDeux>numéro de téléphone : 04 91 05 50 55</MentionTextDeux>
          <MentionTextDeux>adresse du siège social : contact@perroquet.eu</MentionTextDeux>
        </MentionTextContainer>

        <MentionTextContainer>
          <MentionTextDeux>forme juridique de la société : Micro-entreprise</MentionTextDeux>
          <MentionTextDeux>SIREN : 537 879 538</MentionTextDeux>
          <MentionTextDeux>Code APE : 7410 Z</MentionTextDeux>
          <MentionTextDeux>OPCO : ATLAS</MentionTextDeux>
        </MentionTextContainer>

        <MentionText>directeur de publication : Clément CATHERINE.</MentionText>
        <MentionText>Hébergeur : OVH, 2 rue Kellermann – BP 80157 59053 ROUBAIX CEDEX</MentionText>
      </MentionContainer>
  )
}

export default Mentions
