import React from "react";
import styled from "styled-components";

import { FaFacebookF } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';
import { FaLinkedinIn } from 'react-icons/fa';

import LogoDesktop from './LogoDesktop';

const HeaderContainer = styled.header`
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: raw;
  align-items: center;
  justify-content: space-between;
  padding: 0 5%;
`;

const SocialSection = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 120px;
`;

const PerroquetLogo = styled.a`
  width: 34px;
  height: 34px;
  transition: transform 1s ease;

  .logo path { fill: #7600ff; }
  .socialmedia {
    fill: #FFFFFF;
    background-color: #7600ff;
    width: 34px;
    height: 34px;
    padding: 7px;
    border-radius: 2px;
  }

  &:hover { transform: rotate(-360deg); }
`;

const MainLogo = styled.a`
  width: 34px;
  height: 34px;

  img {
    width: 34px;
    height: 34px;
    transition: transform 1s ease;

    &:hover { transform: rotate(-360deg); }
  }
`

const Header = () => {

  const viewportContext = React.createContext({});

  const ViewportProvider = ({children}) => {
    const [width, setWidth] = React.useState(window.innerWidth);

    const handleWindowRezise = () => {
      setWidth(window.innerWidth);
    }

    React.useEffect(() => {
      window.addEventListener('resize', handleWindowRezise);
      return () => window.removeEventListener('resize', handleWindowRezise);
    }, [])

    return (
      <viewportContext.Provider value={{ width }}>
        {children}
      </viewportContext.Provider>
    );
  }

  const useViewport = () => {
    const { width } = React.useContext(viewportContext);
    return { width };
  }

  const MobileComponent = () => <img src={process.env.PUBLIC_URL + './images/Perroquet-Favicon-purple.jpeg'} alt="Logo Perroquet" />;
  const DesktopComponent = () => <LogoDesktop id="logoDesktop" />

  const MyComponent = () => {
    const { width } = useViewport();
    const breakpoint = 768;

    return width < breakpoint ? <MobileComponent /> : <DesktopComponent />
  }

  return (
    <ViewportProvider>
    <HeaderContainer>
      <MainLogo href="https://perroquet.eu/" target="_blank">
        <MyComponent />    
      </MainLogo>

      <SocialSection>
        <PerroquetLogo
          href="https://www.linkedin.com/company/perroquet-design-graphique/"
          target="_blank"
        >
          <FaLinkedinIn className="socialmedia" />
        </PerroquetLogo>
        <PerroquetLogo
          href="https://www.instagram.com/perroquet/"
          target="_blank"
        >
          <FaInstagram className="socialmedia" />
        </PerroquetLogo>
        <PerroquetLogo
          href="https://www.facebook.com/perroquetbooked"
          target="_blank"
        >
          <FaFacebookF className="socialmedia" />
        </PerroquetLogo>
      </SocialSection>
    </HeaderContainer>
    </ViewportProvider>
  );
};

export default Header;
